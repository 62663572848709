'use client'
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';
import {getFromLocale, setToLocale} from "@/utils";
import {useDispatch} from "react-redux";
import {setAuthToken} from "@/store";

const googleOneTapLogin = async (token) => {
    // const res = await fetch(`${process.env.API_FOR_LOGIN}/api/auth/google/one-tap?email=${token.email}`)
    const res = await fetch(`/api/auth/google-one-tap`,{
        method: 'POST',
        body: JSON.stringify({token})
    })
    return await res.json()

};

const GoogleOneTapLogin = () => {
    const router = useRouter();
    const dispatch = useDispatch();
    const authToken = getFromLocale();
    useEffect(() => {

        const timeout = setTimeout(() => oneTap(), 2000);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const oneTap = () => {
        const { google } = window;

        if (google && google.accounts && google.accounts?.id) {

            google?.accounts?.id.initialize({
                client_id: process.env.GOOGLE_CLIENT_ID,
                callback: async (response) => {
                            call(response.credential);
                },
            });
            !authToken && google.accounts.id.prompt((notification) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;`
                    google.accounts.id.prompt()
                }
            });
        }
    };

    const call = async (token) => {
        try {
            const res = await googleOneTapLogin(
                token,
            );
            const expires = new Date();
            expires.setTime(expires.getTime() + (30 * 24 * 60 * 60 * 1000));
            setToLocale({value: res.token })
            dispatch(setAuthToken(res.token));
            router.refresh()
        } catch (error) {
            router.push('/sign-in');
        }
    };
    return <div />;
};

export default GoogleOneTapLogin;