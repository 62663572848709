"use client";

import { store } from "@/store";
import { Provider } from "react-redux";
import Script from "next/script";

export function Providers({ children }) {

  return (<>
    <Provider store={store}>{children}</Provider>
    <Script
        src="https://accounts.google.com/gsi/client"
        strategy="beforeInteractive"
        onLoad={() => console.log('Google One Tap Script Loaded')}
    />
  </>)
}